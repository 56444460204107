import React from 'react';
import {SplitPane} from 'react-collapse-pane';
import {useParams} from 'react-router-dom';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {isEqual} from 'lodash';

import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
import AdModal from '../Components/Modal/AdModal';
import Chart from '../Components/Chart/Chart';
import {setupGroups, join} from '../Helpers/formatData';
import TradingViewWidget from '../Components/Chart/TradingViewWidget';
import LegendView from '../Components/PopOut/LegendView';
import {
  customStyles,
  customStylesOptions,
  customStylesLocked,
  customStylesUpcoming,
  customStylesReports,
  customStylesLegend,
} from './modalStyles';

const Default = ({
  scannersData,
  groupCollection,
  groups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  loadingGroups,
  modalShow,
  handleClose,
  userData,
  showChart,
  closeChart,
  symbolData,
  chartSettings,
  getSymbolData,
  symbolToChart,
  modalIsOpen,
  closeModal,
  groupToChart,
  scannerLayout,
  tabTitle,
  currentDate,
  updatingDate,
  setUpdatingDate,
  hasMainReport,
  showLegendModal,
  openLegendModal,
  closeLegendModal,
}) => {
  const params = useParams();
  const {id: scannerId} = params;
  console.log("showLegendModal", showLegendModal);
  const scannerData = scannersData?.find((s) => s.slug === scannerId);
  const scannerTitle = scannerData?.title;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const sideBySide = scannerData ? scannerData.sideBySide : false;
  const showSideBySide =
    document.getElementById('scan_scanner').getAttribute('data-side-by-side') ?? sideBySide ?? false;

  const reformatGroups =
    Object.keys(groupCollection || {}).length === 3
      ? groups.map((gr, index) => {
          if (index === 1) {
            return {
              group: groupCollection[gr?.group],
              child: groupCollection[groups[index + 1]?.group] ?? null,
            };
          }
          if (index !== 2) {
            return {group: groupCollection[gr?.group]};
          }
          return null;
        })
      : null;

  const scanners = Object.keys(groupCollection || {}).length
    ? reformatGroups?.length && !showSideBySide
      ? reformatGroups
          .filter((item) => item !== null)
          .map((group) => {
            if (group?.child) {
              return (
                <SplitPane
                  key={groupCollection[group?.group] ?? null}
                  split="horizontal"
                  collapse
                  minSize={0}
                  defaultSize={0}
                >
                  <div
                    className={`${
                      Object.keys(groupCollection || {}).length > 1
                        ? 'multi-screen-scanner scanner-group'
                        : ' scanner-group'
                    }`}
                    key={groupCollection[group?.group?.group] ?? null}
                  >
                    <Scanner
                      group={groupCollection[group?.group?.group] ?? groupCollection[group?.child?.group] ?? null}
                      groupNumber={groups.length}
                      dispatch={dispatch}
                      multiScreen
                      hasChild
                      theme={theme}
                      setTheme={setTheme}
                      wsSocket={wsSocket}
                      getSymbolData={getSymbolData}
                      groupCollection={groupCollection}
                      groups={groups}
                      hideSearch={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideSearch)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hideSearch
                          : scannersData?.find((s) => s.slug === params?.id)?.hideSearch === true ?? false
                      }
                      hideHeader={scannersData?.find((s) => s.slug === params?.id)?.hideHeader === true ?? false}
                      useSecondaryHeaderTitle={
                        scannersData?.find((s) => s.slug === params?.id)?.useSecondaryHeaderTitle === true ?? false
                      }
                      hideDateSelectorForGroups={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups
                          : scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups === true ??
                            false
                      }
                      allowExportCSV={
                        scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false
                      }
                      alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
                      hidePagination={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hidePagination)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hidePagination
                          : scannersData?.find((s) => s.slug === params?.id)?.hidePagination === true ?? false
                      }
                      trimNumbersFromSymbol={
                        scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
                      }
                      hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
                      hasMainReport={hasMainReport}
                      displayLegend={scannersData?.find((s) => s.slug === params?.id)?.displayLegend ?? false}
                      showLegendModal={showLegendModal}
                      openLegendModal={openLegendModal}
                      closeLegendModal={closeLegendModal}
                    />
                    {/* hideHeader={window.location.pathname.includes('daily-option-trader')  || window.location.pathname.includes('shadow-signal')}/> */}
                  </div>
                  <div
                    className={`${
                      Object.keys(groupCollection || {}).length > 1
                        ? 'multi-screen-scanner scanner-group'
                        : ' scanner-group'
                    }`}
                    key={groupCollection[group?.child] ?? null}
                  >
                    <Scanner
                      group={groupCollection[group?.child] ?? groupCollection[group.group] ?? null}
                      groupNumber={groups.length}
                      dispatch={dispatch}
                      multiScreen
                      hasChild
                      theme={theme}
                      setTheme={setTheme}
                      wsSocket={wsSocket}
                      getSymbolData={getSymbolData}
                      groupCollection={groupCollection}
                      groups={groups}
                      hideSearch={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideSearch)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hideSearch
                          : scannersData?.find((s) => s.slug === params?.id)?.hideSearch === true ?? false
                      }
                      hideHeader={scannersData?.find((s) => s.slug === params?.id)?.hideHeader === true ?? false}
                      allowExportCSV={
                        scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false
                      }
                      useSecondaryHeaderTitle={
                        scannersData?.find((s) => s.slug === params?.id)?.useSecondaryHeaderTitle === true ?? false
                      }
                      hideDateSelectorForGroups={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups
                          : scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups === true ??
                            false
                      }
                      alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
                      hidePagination={
                        Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hidePagination)
                          ? scannersData?.find((s) => s.slug === params?.id)?.hidePagination
                          : scannersData?.find((s) => s.slug === params?.id)?.hidePagination === true ?? false
                      }
                      trimNumbersFromSymbol={
                        scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
                      }
                      currentDate={currentDate}
                      optionsColumns={scannersData?.find((s) => s.slug === params?.id)?.optionsColumns ?? {}}
                      optionsAllowed={scannersData?.find((s) => s.slug === params?.id)?.optionsAllowed ?? false}
                      hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
                      hasMainReport={hasMainReport}
                      displayLegend={scannersData?.find((s) => s.slug === params?.id)?.displayLegend ?? false}
                      showLegendModal={showLegendModal}
                      openLegendModal={openLegendModal}
                      closeLegendModal={closeLegendModal}
                    />
                  </div>
                </SplitPane>
              );
            }
            return (
              <div
                className={`${
                  Object.keys(groupCollection || {}).length > 1
                    ? 'multi-screen-scanner scanner-group'
                    : ' scanner-group'
                }`}
                key={groupCollection[group?.group?.group] ?? null}
              >
                <Scanner
                  group={groupCollection[group?.group?.group] ?? groupCollection[group.group] ?? null}
                  groupNumber={groups.length}
                  dispatch={dispatch}
                  multiScreen
                  hasChild
                  theme={theme}
                  setTheme={setTheme}
                  wsSocket={wsSocket}
                  getSymbolData={getSymbolData}
                  groupCollection={groupCollection}
                  groups={groups}
                  hideSearch={
                    Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideSearch)
                      ? scannersData?.find((s) => s.slug === params?.id)?.hideSearch
                      : scannersData?.find((s) => s.slug === params?.id)?.hideSearch === true ?? false
                  }
                  hideHeader={scannersData?.find((s) => s.slug === params?.id)?.hideHeader === true ?? false}
                  allowExportCSV={scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false}
                  useSecondaryHeaderTitle={
                    scannersData?.find((s) => s.slug === params?.id)?.useSecondaryHeaderTitle === true ?? false
                  }
                  hidePagination={
                    Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hidePagination)
                      ? scannersData?.find((s) => s.slug === params?.id)?.hidePagination
                      : scannersData?.find((s) => s.slug === params?.id)?.hidePagination === true ?? false
                  }
                  alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
                  trimNumbersFromSymbol={
                    scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
                  }
                  currentDate={currentDate}
                  optionsColumns={scannersData?.find((s) => s.slug === params?.id)?.optionsColumns ?? {}}
                  optionsAllowed={scannersData?.find((s) => s.slug === params?.id)?.optionsAllowed ?? false}
                  hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
                  hasMainReport={hasMainReport}
                  displayLegend={scannersData?.find((s) => s.slug === params?.id)?.displayLegend ?? false}
                  showLegendModal={showLegendModal}
                  openLegendModal={openLegendModal}
                  closeLegendModal={closeLegendModal}
                />
                <div className="clearfix" />
              </div>
            );
          })
      : groups?.map((group) => (
          <div
            className={`${
              Object.keys(groupCollection || {}).length > 1 ? 'multi-screen-scanner scanner-group' : ' scanner-group'
            }`}
            key={group}
          >
            <Scanner
              group={groupCollection[group.group] ?? null}
              groupNumber={groups.length}
              dispatch={dispatch}
              multiScreen={Object.keys(groupCollection || {}).length > 1}
              theme={theme}
              setTheme={setTheme}
              wsSocket={wsSocket}
              getSymbolData={getSymbolData}
              groupCollection={groupCollection}
              groups={groups}
              useSecondaryHeaderTitle={
                scannersData?.find((s) => s.slug === params?.id)?.useSecondaryHeaderTitle === true ?? false
              }
              hideSearch={
                Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideSearch)
                  ? scannersData?.find((s) => s.slug === params?.id)?.hideSearch
                  : scannersData?.find((s) => s.slug === params?.id)?.hideSearch === true ?? false
              }
              hideHeader={scannersData?.find((s) => s.slug === params?.id)?.hideHeader === true ?? false}
              trimNumbersFromSymbol={
                scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
              }
              allowExportCSV={scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false}
              hideDateSelectorForGroups={
                Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups)
                  ? scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups
                  : scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups === true ?? false
              }
              hidePagination={
                Array.isArray(scannersData?.find((s) => s.slug === params?.id)?.hidePagination)
                  ? scannersData?.find((s) => s.slug === params?.id)?.hidePagination
                  : scannersData?.find((s) => s.slug === params?.id)?.hidePagination === true ?? false
              }
              optionsColumns={scannersData?.find((s) => s.slug === params?.id)?.optionsColumns ?? []}
              alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
              currentDate={currentDate}
              hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
              hasMainReport={hasMainReport}
              displayLegend={scannersData?.find((s) => s.slug === params?.id)?.displayLegend ?? false}
              showLegendModal={showLegendModal}
              openLegendModal={openLegendModal}
              closeLegendModal={closeLegendModal}
            />
            <div className="clearfix" />
          </div>
        ))
    : null;

  if (
    groupCollection &&
    Object.keys(groupCollection || {})?.length &&
    scanners &&
    scanners.length > 1 &&
    !loadingGroups
  ) {
    return (
      <>
        <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
        {/* {showChart ? <Chart chartSettings={chartSettings} symbolData={symbolData} symbolToChart={symbolToChart} closeChart={closeChart} /> : null} */}
        <ScannerHeader
          name={scannerTitle}
          theme={theme}
          setTheme={setTheme}
          userData={userData}
          showChart={showChart}
          groupCollection={groupCollection}
          dispatch={dispatch}
          groups={groups}
          wsSocket={wsSocket}
          showSelector={
            window.location.pathname.includes('daily-option-trader') ||
            window.location.pathname.includes('oa-watchlist')
          }
          currentDate={currentDate}
          updatingDate={updatingDate}
          setUpdatingDate={setUpdatingDate}
          // showSelector={window.location.pathname.includes('daily-option-trader') || window.location.pathname.includes('shadow-signal')}
        />
        <SplitPane
          split={scannerLayout === 'horizontal-stack-layout' ? 'vertical' : 'horizontal'}
          collapse
          minSize={0}
          defaultSize={0}
        >
          {scanners}
        </SplitPane>
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
          <div className="close-modal-wrap flex items-center justify-end p-1">
            <button className="close-modal cursor-pointer" type="button" onClick={closeModal}>
              <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
            </button>
          </div>

          <TradingViewWidget
            symbolData={symbolData}
            symbolToChart={symbolToChart}
            groupToChart={groupToChart}
            getSymbolData={getSymbolData}
            chartSettings={chartSettings}
            wsSocket={wsSocket}
            groupCollection={groupCollection}
            closeChart={closeChart}
            theme={theme}
          />
        </Modal>
        {/* Legend Modal */}
        <Modal isOpen={showLegendModal} onRequestClose={closeLegendModal} style={customStylesLegend}>
          <div className="close-modal-wrap flex items-center justify-end p-1">
            <button className="close-modal cursor-pointer" type="button" onClick={closeLegendModal}>
              <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
            </button>
          </div>
          <div>
            <LegendView groupCollection={groupCollection} />
          </div>
        </Modal>
      </>
    );
  }
  if (
    groupCollection &&
    Object.keys(groupCollection || {})?.length &&
    !loadingGroups &&
    scanners &&
    scanners.length === 1
  ) {
    return (
      <>
        <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
        {/* {showChart ? <Chart chartSettings={chartSettings} symbolData={symbolData} symbolToChart={symbolToChart} closeChart={closeChart} /> : null} */}
        <ScannerHeader
          name={scannerTitle}
          theme={theme}
          setTheme={setTheme}
          userData={userData}
          showChart={showChart}
          groupCollection={groupCollection}
          dispatch={dispatch}
          groups={groups}
          wsSocket={wsSocket}
          showSelector={
            window.location.pathname.includes('daily-option-trader') ||
            window.location.pathname.includes('oa-watchlist')
          }
          currentDate={currentDate}
          updatingDate={updatingDate}
          setUpdatingDate={setUpdatingDate}
        />
        {scanners}
        {/* Trading Chart Modal */}
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
          <div className="close-modal-wrap flex items-center justify-end p-1">
            <button className="close-modal cursor-pointer" type="button" onClick={closeModal}>
              <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
            </button>
          </div>
          <TradingViewWidget
            symbolData={symbolData}
            symbolToChart={symbolToChart}
            groupToChart={groupToChart}
            getSymbolData={getSymbolData}
            chartSettings={chartSettings}
            wsSocket={wsSocket}
            groupCollection={groupCollection}
            closeChart={closeChart}
            theme={theme}
          />
        </Modal>
        {/* Legend Modal */}
        <Modal isOpen={showLegendModal} onRequestClose={closeLegendModal} style={customStylesLegend}>
          <div className="close-modal-wrap flex items-center justify-end p-1">
            <button className="close-modal cursor-pointer" type="button" onClick={closeLegendModal}>
              <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
            </button>
          </div>
          <div>
            <LegendView groupCollection={groupCollection} />
          </div>
        </Modal>
      </>
    );
  }

  if (!groups || !groups?.length) {
    return (
      <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
        <div className="loading-user-wrapper">
          {/* <div className="mb-2 text-center animate-pulse">
          <img src={SpyderIcon} alt="Spyder Scanner" className="inline-block" />
        </div> */}
          <div className="mb-2 text-center">
            <div className="loader" />
          </div>
          <span>No Scanners Available</span>
        </div>
      </div>
    );
  }
  return (
    <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
      <div className="loading-user-wrapper">
        {/* <div className="mb-2 text-center animate-pulse">
          <img src={SpyderIcon} alt="Spyder Scanner" className="inline-block" />
        </div> */}
        <div className="mb-2 text-center">
          <div className="loader" />
        </div>
        {/* <span>Loading...</span> */}
      </div>
    </div>
  );
};

const optionValuesSame = (scannersData, prev, next) => {
  const id = window.location.pathname.replace('/scanners/', '');
  const optionsLayout = scannersData?.find((s) => s.slug === id)?.optionsLayout ?? {};
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (!isEqual(prevSymbolData, nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every((k) => isEqual(prevSymbolData?.[k]?.value, nextSymbolData?.[k]?.value));
    });
    return areSame;
  }
  return true;
};

export default React.memo(Default, (prev, next) => {
  if (prev?.showLockedModal && next?.showLockedModal) {
    return prev?.showLockedModal === next?.showLockedModal;
  }
  if (prev?.showUpcomingModal && next?.showUpcomingModal) {
    return prev?.showUpcomingModal === next?.showUpcomingModal;
  }
  if (prev?.showOptionsModal && next?.showOptionsModal) {
    return prev.optionToView === next.optionToView && optionValuesSame(next?.scannersData, prev, next);
  }
  if (prev?.showReportModal && next?.showReportModal) {
    return prev.showReportModal === next.showReportModal;
  }
  if (prev?.modalIsOpen && next?.modalIsOpen) {
    return prev?.groupToChart === next?.groupToChart && prev?.symbolToChart === next?.symbolToChart;
  }

  const collectionSame = isEqual(prev?.groupCollection, next?.groupCollection);
  const groupsSame = isEqual(prev?.groups, next?.groups);
  const showOptionsModalSame = prev.showOptionsModal === next.showOptionsModal;
  const showReportModalSame = prev.showReportModal === next.showReportModal;
  const modalIsOpenSame = prev?.modalIsOpen === next?.modalIsOpen;
  const showLockedModalSame = prev?.showLockedModal === next?.showLockedModal;
  const showUpcomingModalSame = prev?.showUpcomingModal === next?.showUpcomingModal;
  const sameDate = prev?.group?.selectedDate === next?.group?.selectedDate;
  const sameUpdatingDate = prev?.updatingDate === next?.updatingDate;
  const currentDateSame = prev?.currentDate === next?.currentDate;

  const showLegendModalSame = prev?.showLegendModal === next?.showLegendModal;

  return (
    collectionSame &&
    groupsSame &&
    showOptionsModalSame &&
    modalIsOpenSame &&
    showLockedModalSame &&
    showUpcomingModalSame &&
    sameDate &&
    showReportModalSame &&
    sameUpdatingDate &&
    currentDateSame && 
    showLegendModalSame
  );
});
